import { combineReducers } from 'redux';
import userInfo from './slices/userInfo';
import appInfo from './slices/appInfo';
import commonData from './slices/commonData';
import tasks from './slices/tasks';

const rootReducer = combineReducers({ userInfo, appInfo, commonData, tasks });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
