import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/reducers';
import { NavigationProvider } from '../hooks/useNavigate';
import { LayoutType } from '../utils/enums';
import {
    Drawer,
    Modal,
    notification as Notification,
    NotificationArgsProps,
} from 'antd';
import { ICCheckFill, ICClose, ICWarning } from '../icons';
import { ScrollTop } from '../hooks/useScrollTop';
import AntdWrapApp from '../message';
import { TBreadcrumbs } from '../layout/components/breadcrumbs';
import { AdminLayout } from '../layout';
import { useMe } from '../hooks/useMe';
import { TNotification } from '../interface/user/user';
import {
    getSignalRConnection,
    startSignalRConnection,
} from '../utils/signalrClient';
import { ackReceived, searchNotification } from '../api/notification';
import {
    NotificationTypeMode,
    TNotificationAction,
    TSearchNotification,
} from '../api/notification/type';
import { useScreenSize } from '../hooks/useScreenSize';
import { NameOfCar } from './components/name-of-car';
import { ViewServices } from './all-work-orders/view-services';
import { AutoTrackButton } from '../components/button';
import { CardServices } from './all-work-orders/card-services';
import { TCarInService } from './start-work-order/type';
import { setRefreshData } from '../redux/actions';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type TGeneralPageDataProps = {
    openNotification: (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode
    ) => void;
    breadcrumbs: TBreadcrumbs[];
    setBreadcrumbs: React.Dispatch<React.SetStateAction<TBreadcrumbs[]>>;
    titlePage: string;
    setTitlePage: React.Dispatch<React.SetStateAction<string>>;
    notifications: TNotification[];
    setNotifications: React.Dispatch<React.SetStateAction<TNotification[]>>;
    isShowServices: boolean;
    setIsShowServices: React.Dispatch<React.SetStateAction<boolean>>;
    taskSelected: TCarInService | undefined;
    setTaskSelected: React.Dispatch<
        React.SetStateAction<TCarInService | undefined>
    >;
};

export const GeneralPageDataContext =
    React.createContext<TGeneralPageDataProps>({} as TGeneralPageDataProps);
export const useGeneralPageData = () =>
    React.useContext(GeneralPageDataContext);

type GeneralPageProps = {
    page: React.ReactElement;
    isPrivate?: boolean;
    layout?: LayoutType;
    classes?: string;
};
export const GeneralPage: React.FC<GeneralPageProps> = ({
    page,
    isPrivate = false,
    classes,
    layout = LayoutType.ADMIN,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api, contextHolder] = Notification.useNotification();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [breadcrumbs, setBreadcrumbs] = React.useState<TBreadcrumbs[]>([]);
    const [titlePage, setTitlePage] = React.useState<string>('');
    const [notifications, setNotifications] = React.useState<TNotification[]>(
        []
    );
    const { userId, getMe } = useMe();
    type NotificationPlacement = NotificationArgsProps['placement'];
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm' || size === 'md';
    const [isShowServices, setIsShowServices] = React.useState(false);
    const [taskSelected, setTaskSelected] = React.useState<
        TCarInService | undefined
    >(undefined);

    const openNotification = (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode,
        placement?: NotificationPlacement
    ) => {
        Notification.destroy();
        if (type === 'success') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'info') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'warning') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'error') {
            return api[type]({
                icon: <ICWarning fill="#F03D3D" />,
                message: (
                    <span className="text-body-bold text-high-em">{title}</span>
                ),
                // duration: 99999,
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
    };

    const [pageContent, setPageContent] = React.useState<React.ReactElement>(
        <></>
    );

    const setDataNotification = (data: any) => {
        if (typeof data === 'string' && data?.startsWith('{')) {
            const newNotification = JSON.parse(data);
            if (typeof newNotification !== 'object') {
                return;
            }
            if (newNotification?.UserId !== userId) {
                return;
            }
            setNotifications(prev => {
                const prevNotifications = Array.isArray(prev) ? prev : [];
                const newNotificationTemp = {
                    createdAt: newNotification?.CreatedAt,
                    remainTime: newNotification?.RemainTime,
                    serviceDetailId: newNotification?.ServiceDetailId,
                    serviceId: newNotification?.ServiceId,
                    serviceName: newNotification?.ServiceName,
                    signalRNotificationId:
                        newNotification?.SignalRNotificationId,
                    userId: newNotification?.UserId,
                    carInfo: {
                        carId: newNotification?.CarInfo?.CarId,
                        make: newNotification?.CarInfo?.Make,
                        model: newNotification?.CarInfo?.Model,
                        year: newNotification?.CarInfo?.Year,
                        color: newNotification?.CarInfo?.Color,
                        vin: newNotification?.CarInfo?.Vin,
                        licensePlate: newNotification?.CarInfo?.LicensePlate,
                    },
                };
                const exists = prevNotifications.some(
                    item =>
                        item?.signalRNotificationId ===
                        newNotificationTemp?.signalRNotificationId
                );

                if (prevNotifications.length === 0) {
                    return [newNotificationTemp];
                }
                if (exists) {
                    return prevNotifications.map(item =>
                        item?.signalRNotificationId ===
                        newNotificationTemp?.signalRNotificationId
                            ? newNotificationTemp
                            : item
                    );
                }
                return [newNotificationTemp, ...prevNotifications];
            });
            openNotification(
                'info',
                'Notification',
                newNotification?.ServiceName
            );
            if (
                newNotification?.SignalRNotificationId &&
                newNotification?.UserId
            ) {
                setAckReceived(
                    newNotification?.SignalRNotificationId,
                    newNotification?.UserId
                );
            }
        }
    };

    const initSignalR = async () => {
        try {
            await startSignalRConnection();
            const connection = await getSignalRConnection();
            if (!connection) {
                return;
            }
            connection?.on(
                'NotificationHub',
                (user: string, message: string) => {
                    setDataNotification(message);
                }
            );
        } catch (error) {
            // TODO: handle error
        }
    };

    const loadNotification = async (userIdVal: number | string) => {
        try {
            const payload: TSearchNotification = {
                userId: userIdVal,
                type: NotificationTypeMode.All,
                page: 1,
                pageSize: 10,
            };
            const result = await searchNotification(payload);
            if (result.status === 200) {
                const newData = result?.data?.data || [];
                setNotifications(prev => {
                    return [...prev, ...newData];
                });
            }
        } catch (error) {
            openNotification('error', 'Error when load notification');
        }
    };

    const setAckReceived = async (
        signalRNotificationId: number,
        userIdVal: number | string
    ) => {
        try {
            const payload: TNotificationAction = {
                userId: userIdVal,
                signalRNotificationId: signalRNotificationId,
            };
            await ackReceived(payload);
        } catch (error) {
            openNotification('error', 'Error when set ack received');
        }
    };

    const initData = async () => {
        try {
            await getMe();
            await initSignalR();
        } catch (error) {
            openNotification('error', 'Error when init data');
        }
    };

    React.useEffect(() => {
        if (isPrivate) {
            initData();
            if (userId) loadNotification(userId);
        }
    }, [userId]);

    React.useEffect(() => {
        if (classes) {
            document.body.className = classes;
        } else {
            document.body.className = '';
        }
        setTitlePage('');
        if (
            (isPrivate || typeof isPrivate === 'undefined') &&
            !userInfo.token
        ) {
            navigate('/login');
        } else {
            if (layout === LayoutType.ADMIN) {
                return setPageContent(<AdminLayout>{page}</AdminLayout>);
            } else {
                setPageContent(page);
            }
        }
    }, [page, userInfo, isPrivate, classes, navigate, layout]);

    const onRefreshData = React.useCallback(() => {
        setTaskSelected(undefined);
        setIsShowServices(false);
        dispatch(setRefreshData(true));
    }, [dispatch]);

    const renderFooter = React.useMemo(() => {
        if (!taskSelected) {
            return null;
        }
        const durationInMinutes = taskSelected?.services?.reduce(
            (acc, service) => acc + (service?.durationInMinutes || 0),
            0
        ) as number;
        const countDuration =
            durationInMinutes && durationInMinutes < 60
                ? durationInMinutes
                : durationInMinutes
                  ? (durationInMinutes / 60).toFixed(2)
                  : 0;
        const renderText = durationInMinutes < 60 ? 'minutes' : 'hours';
        return (
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-2">
                    <span className="text-muted font-normal text-[12px]">
                        Total Duration:
                    </span>
                    <span className="font-bold">
                        {countDuration} {renderText}
                    </span>
                </div>
            </div>
        );
    }, [taskSelected]);

    return (
        <NavigationProvider>
            <GeneralPageDataContext.Provider
                value={{
                    openNotification,
                    breadcrumbs,
                    setBreadcrumbs,
                    titlePage,
                    setTitlePage,
                    notifications,
                    setNotifications,
                    isShowServices,
                    setIsShowServices,
                    taskSelected,
                    setTaskSelected,
                }}
            >
                <AntdWrapApp />
                {contextHolder}
                {pageContent}
                <ScrollTop />
                {!mobileMode && (
                    <Modal
                        title={<NameOfCar car={taskSelected?.car} />}
                        open={isShowServices}
                        onCancel={() => setIsShowServices(false)}
                        centered
                        destroyOnClose
                        afterClose={() => {
                            onRefreshData();
                        }}
                        footer={null}
                        width={800}
                    >
                        <ViewServices task={taskSelected} />
                    </Modal>
                )}
                {mobileMode && (
                    <Drawer
                        title={
                            <div className="flex items-center justify-between gap-3">
                                <div className="flex flex-col gap-2">
                                    <h3>
                                        <NameOfCar car={taskSelected?.car} />
                                    </h3>
                                    <div>{renderFooter}</div>
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => setIsShowServices(false)}
                                >
                                    <AutoTrackButton
                                        btnSize="sm"
                                        btnType="sub_danger"
                                    >
                                        Close
                                    </AutoTrackButton>
                                </div>
                            </div>
                        }
                        onClose={() => setIsShowServices(false)}
                        open={isShowServices}
                        destroyOnClose
                        closable={false}
                        zIndex={1000}
                        width={'100%'}
                        height={'90%'}
                        placement="bottom"
                        afterOpenChange={visible => {
                            if (!visible) {
                                onRefreshData();
                            }
                        }}
                        classNames={{
                            body: 'body-card-services-drawer',
                        }}
                    >
                        <CardServices
                            task={taskSelected}
                            onUpdateCarInService={setTaskSelected}
                        />
                    </Drawer>
                )}
            </GeneralPageDataContext.Provider>
        </NavigationProvider>
    );
};
