import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type Tasks = {
    refreshData: boolean;
};

const initialState = {
    refreshData: false,
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setRefreshData: (state: Tasks, action: PayloadAction<boolean>) => {
            state.refreshData = action.payload;
        },
    },
});

export const { setRefreshData } = tasksSlice.actions;

export default tasksSlice.reducer;
