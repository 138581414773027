import { Badge, Dropdown, Tabs, TabsProps } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { ICNotification } from '../../../icons';
import { CheckOutlined } from '@ant-design/icons';
import { useGeneralPageData } from '../../../pages/general';
import { timeAgo } from '../../../utils';
import { TNotification } from '../../../interface/user/user';
import styled from 'styled-components';
import { TNotificationAction } from '../../../api/notification/type';
import { ackRead } from '../../../api/notification';
import { AutoTrackTooltip } from '../../../components/tooltip';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import { getCarInServiceById } from '../../../api/car-in-service';
// const idb = require('idb');

const TabsWrapper = styled.div`
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
        display: none;
    }
    .ant-tabs > .ant-tabs-nav {
        margin-bottom: 7px;
    }
`;

const WrapperNotificationList = styled.div`
    .ant-dropdown-menu-item {
        padding: 10px !important;
    }
    li {
        cursor: pointer;
        padding: 10px 15px;
        border-bottom: 1px solid #f0f0f0;
        box-sizing: border-box;
        &:hover {
            background: #f5f5f5;
        }
    }
`;

const Wrapper = styled.div`
    .ant-dropdown-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;

export const NotificationControl: React.FC = () => {
    const {
        notifications,
        setNotifications,
        openNotification,
        setIsShowServices,
        setTaskSelected,
    } = useGeneralPageData();
    const dispatch = useDispatch();
    const [notificationList, setNotificationList] = React.useState<
        TNotification[]
    >([]);
    const [activeNotification, setActiveNotification] = React.useState<
        'unread' | 'read'
    >('unread');
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    React.useEffect(() => {
        setNotificationList(notifications);
    }, [notifications]);

    const unreadCount = React.useMemo(() => {
        return notificationList?.filter(item => !item.readAt).length;
    }, [notificationList]);

    const readCount = React.useMemo(() => {
        return notificationList?.filter(item => item.readAt).length;
    }, [notificationList]);

    const onSelectNotification = React.useCallback(
        async (notification: TNotification) => {
            try {
                if (
                    !notification.carInServiceId ||
                    !notification?.signalRNotificationId
                ) {
                    return;
                }
                dispatch(setLoading(true));
                const result = await getCarInServiceById(
                    notification.carInServiceId
                );
                if (result.status === 200) {
                    const data = result.data ?? [];
                    if (Array.isArray(data)) {
                        setTaskSelected(data[0]);
                        setIsShowServices(true);
                    }
                    setIsDropdownOpen(false);
                    setMarkReadNotification(notification);
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [setIsShowServices, setIsDropdownOpen, dispatch]
    );

    const renderNotificationList = React.useMemo(() => {
        if (!notificationList) {
            return null;
        }
        const notificationFilter = notificationList.filter(item => {
            if (activeNotification === 'unread') {
                return !item.readAt;
            }
            return item.readAt;
        });
        if (notificationFilter?.length === 0) {
            return null;
        }
        const notificationsTemp = notificationFilter?.sort((a, b) => {
            return (
                new Date(b?.createdAt ?? 0).getTime() -
                new Date(a?.createdAt ?? 0).getTime()
            );
        });
        return notificationsTemp.map((notification, index) => {
            const timestamp = notification?.createdAt?.toString()
                ? timeAgo(notification?.createdAt?.toString() ?? '')
                : '';
            return (
                <div
                    key={index}
                    className={classNames(
                        'flex flex-col gap-[15px] items-start justify-start w-full overflow-auto max-h-[200px] p-2 rounded-md hover:bg-gray-100',
                        {
                            'unread-notification': !notification?.readAt,
                        }
                    )}
                    onClick={() => onSelectNotification(notification)}
                >
                    <div className="flex flex-col w-full relative">
                        <div className="flex gap-3 items-start justify-between w-full">
                            <div className="flex flex-col gap-0 items-start">
                                <h3
                                    className={classNames([
                                        'text-[16px]',
                                        notification?.readAt &&
                                            'text-gray-600 font-normal',
                                        !notification.readAt &&
                                            'text-body-bold',
                                    ])}
                                >
                                    {notification?.serviceName}
                                </h3>
                                <h6 className="text-xs text-low-em">
                                    {notification?.carInfo?.licensePlate}
                                </h6>
                            </div>
                            <div
                                className={classNames([
                                    'flex items-center text-[12px] text-gray-600',
                                ])}
                            >
                                {timestamp}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }, [notificationList, activeNotification]);

    const notificationItems: TabsProps['items'] = [
        {
            key: 'unread',
            label: (
                <Badge
                    count={unreadCount}
                    overflowCount={99}
                    style={{ backgroundColor: '#FF4D4F', left: '35px' }}
                >
                    Unread
                </Badge>
            ),
            children: (
                <WrapperNotificationList className="flex flex-col w-full h-full overflow-auto max-h-[250px] custom-scroll-bar">
                    {renderNotificationList}
                </WrapperNotificationList>
            ),
        },
        {
            key: 'read',
            label: (
                <Badge
                    count={readCount}
                    overflowCount={99}
                    style={{ backgroundColor: '#1890FF', left: '24px' }}
                >
                    Read
                </Badge>
            ),
            children: (
                <WrapperNotificationList className="flex flex-col w-full h-full overflow-auto max-h-[250px] custom-scroll-bar gap-3">
                    {renderNotificationList}
                </WrapperNotificationList>
            ),
        },
    ];

    const setMarkReadNotification = React.useCallback(
        async (notification: TNotification) => {
            try {
                if (
                    !notification?.signalRNotificationId ||
                    !notification?.userId ||
                    notification?.readAt
                ) {
                    return;
                }
                const payload: TNotificationAction = {
                    userId: notification.userId,
                    signalRNotificationId: notification.signalRNotificationId,
                };
                await ackRead(payload);
                setNotifications(prev => {
                    return prev.map(item => {
                        if (
                            item.signalRNotificationId ===
                            notification.signalRNotificationId
                        ) {
                            return {
                                ...item,
                                readAt: true,
                            };
                        }
                        return item;
                    });
                });
            } catch (error) {
                openNotification('error', 'Error when set ack received');
            }
        },
        []
    );

    const onMarkRead = React.useCallback(async () => {
        try {
            if (!notifications || notifications?.length === 0) {
                return;
            }
            const notificationUnread = notifications.filter(
                item => !item.readAt
            );
            if (notificationUnread?.length === 0) {
                return;
            }
            notificationUnread.forEach(async item => {
                if (!item?.signalRNotificationId || !item?.userId) {
                    return;
                }
                const payload: TNotificationAction = {
                    userId: item.userId,
                    signalRNotificationId: item.signalRNotificationId,
                };
                await ackRead(payload);
            });
            setNotifications(prev => {
                return prev.map(item => {
                    return {
                        ...item,
                        readAt: true,
                    };
                });
            });
        } catch (error) {
            openNotification('error', 'Error when set ack received');
        }
    }, [notifications]);

    const countUnread = React.useMemo(() => {
        return notificationList?.filter(item => !item.readAt).length;
    }, [notificationList]);

    return (
        <Wrapper className="flex items-center justify-center relative max-h-[60px]">
            <div className="relative h-full flex items-center justify-center cursor-pointer">
                <Dropdown
                    menu={{ items: [] }}
                    overlayClassName="user-dropdown notification-dropdown"
                    getPopupContainer={triggerNode =>
                        triggerNode.parentNode as HTMLElement
                    }
                    overlayStyle={{
                        minWidth: '320px',
                        borderRadius: '12px',
                        color: '#110C22',
                        fontWeight: 500,
                    }}
                    open={isDropdownOpen}
                    onOpenChange={open => setIsDropdownOpen(open)}
                    trigger={['click']}
                    placement="bottomRight"
                    arrow={notificationList?.length > 0}
                    dropdownRender={() =>
                        notificationList?.length > 0 ? (
                            <div className="notification-dropdown bg-white rounded-[12px] p-[5px] shadow-lg">
                                <div className="flex items-center justify-between p-3 pb-0">
                                    <h3 className="text-[16px] m-0">
                                        Notifications
                                    </h3>
                                    <div
                                        aria-hidden="true"
                                        className="underline cursor-pointer"
                                        onClick={onMarkRead}
                                    >
                                        <AutoTrackTooltip
                                            content="Mark all as read"
                                            placement="top"
                                            childNode={
                                                <span>
                                                    <CheckOutlined />
                                                </span>
                                            }
                                        />
                                    </div>
                                </div>
                                <TabsWrapper className="flex flex-col w-full h-full px-3 pb-3">
                                    <Tabs
                                        defaultActiveKey="unread"
                                        items={notificationItems}
                                        onChange={key => {
                                            setActiveNotification(
                                                key as 'unread' | 'read'
                                            );
                                        }}
                                    />
                                </TabsWrapper>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                >
                    <a onClick={e => e.preventDefault()} aria-hidden="true">
                        <Badge
                            count={countUnread}
                            offset={[0, 0]}
                            overflowCount={99}
                        >
                            <ICNotification />
                        </Badge>
                    </a>
                </Dropdown>
            </div>
        </Wrapper>
    );
};
